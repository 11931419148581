'use client';

import { FunctionComponent, useMemo } from 'react';
import styles from './styles.module.scss';
import Typical from '../Typical';
import ButtonLink from '../ButtonLink';
import { CreateOptionTypes, listOfCreateOptions } from './listOfCreateOptions';
import Link from 'next/link';
import { ImageWithFallback } from '../ImageWithFallback';
import { useIam } from '../../services/IAm/hooks';

type CardProps = {
    option: CreateOptionTypes;
    index: number;
    cardLink?: string;
};

const Card: FunctionComponent<CardProps> = ({ option, index, cardLink }) => {
    return (
        <Link
            href={cardLink || `/for-educators`}
            key={`${option.slug}`}
            className={styles.card}
            aria-label={option.label}
        >
            <div className={styles.card_image}>
                <ImageWithFallback
                    src={option.image}
                    fallback={option.fallback}
                    alt={`Create ${option.label} with Cademy`}
                    fill
                    priority={index < 5 ? true : false}
                    sizes="max-width(640px) 12em, 14em"
                />
            </div>
            <h3>{option.label}</h3>
        </Link>
    );
};

type CreateWithCademyCarouselPropTypes = {
    cardLink?: string;
    heading?: string;
    subheading?: string;
};

export const CreateWithCademyCarousel: FunctionComponent<CreateWithCademyCarouselPropTypes> = ({
    cardLink,
    heading,
    subheading,
}) => {
    const { iam, iamReady } = useIam();
    const isAdmin = useMemo<boolean>(
        () => iamReady && iam.authenticated && iam.educators.length > 0,
        [iamReady, iam]
    );
    const defaultCardLink = useMemo(
        () =>
            isAdmin
                ? `${process.env.NEXT_PUBLIC_ADMIN_URL}/courses`
                : `${process.env.NEXT_PUBLIC_MARKETPLACE_URL}/create-educator`,
        [isAdmin]
    );
    const buttonLabel = useMemo(
        () => (isAdmin ? 'View Admin Dashboard' : 'Sign up for Free'),
        [isAdmin]
    );

    return (
        <div className={styles.wrapper}>
            <div className={styles.heading}>
                {heading ? (
                    <h2>{heading}</h2>
                ) : (
                    <>
                        <h2>Create Amazing</h2>
                        <Typical
                            steps={[
                                ' Learning',
                                1400,
                                ' Courses',
                                1400,
                                ' Training',
                                1400,
                                ' Events',
                                1400,
                                ' Workshops',
                                1400,
                                ' Webinars',
                                1400,
                                ' Classes',
                                1400,
                            ]}
                            loop="Infinity"
                            wrapper="h2"
                        />
                    </>
                )}
            </div>
            <p>{subheading || 'What will you create today?'}</p>
            <div className={styles.cta_container}>
                <ButtonLink
                    href={defaultCardLink}
                    target="_blank"
                    rel="noopener nofollow"
                    preset="primary"
                >
                    {buttonLabel}
                </ButtonLink>
            </div>

            <div className={styles.carousel_container}>
                {listOfCreateOptions.map((o, i) => (
                    <Card key={i} option={o} index={i} cardLink={cardLink || defaultCardLink} />
                ))}
            </div>
        </div>
    );
};
